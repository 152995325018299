import {
  devSlugMappings,
  devValidProductIds,
  devBannerProductId,
  devBannerVariantId,
  devRecommendedSetProductId,
  devRecommendedSetVariantId,
} from "./productConstants";

import {
  prodSlugMappings,
  prodValidProductIds,
  prodBannerProductId,
  prodBannerVariantId,
  prodRecommendedSetProductId,
  prodRecommendedSetVariantId,
} from "./productionProductConstants";
interface Product {
  productId: number;
  variantIds: number[];
}

let slugMappings: { [key: string]: Product } = {};
let validProductIds: any;
let bannerProductId: any;
let bannerVariantId: any;
let recommendedSetProductId: any;
let recommendedSetVariantId: any;

if (
  process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN?.includes(
    "dev-store.alethahealth.com"
  )
) {
  slugMappings = devSlugMappings;
  validProductIds = devValidProductIds;
  bannerProductId = devBannerProductId;
  bannerVariantId = devBannerVariantId;
  recommendedSetProductId = devRecommendedSetProductId;
  recommendedSetVariantId = devRecommendedSetVariantId;
} else {
  slugMappings = prodSlugMappings;
  validProductIds = prodValidProductIds;
  bannerProductId = prodBannerProductId;
  bannerVariantId = prodBannerVariantId;
  recommendedSetProductId = prodRecommendedSetProductId;
  recommendedSetVariantId = prodRecommendedSetVariantId;
}

export const setProductDetails = (
  slug: string,
  product: { productId?: number; variantIds?: number[] }
) => {
    const mapping = slugMappings[slug];

    if (mapping) {
      product.productId = mapping.productId;
      product.variantIds = mapping.variantIds;
    }

};

export {
  validProductIds,
  bannerProductId,
  bannerVariantId,
  recommendedSetProductId,
  recommendedSetVariantId,
};
