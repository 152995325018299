interface Product {
  productId: number;
  variantIds: number[];
}

export const prodSlugMappings: { [key: string]: Product } = {
  book: {
    productId: 4167455015011,
    variantIds: [30318551203939,30318551138403], //ebook,paperback
  },
  band: { productId: 6546274156643, variantIds: [39286140895331] },
  orbit: { productId: 4167630651491, variantIds: [30318615822435] },
  range: { productId: 6633840476259, variantIds: [39622385401955,42967035674723] },//white,black
  mark: { productId: 6878347133027, variantIds: [42967033872483,42967033905251] },//white,black
  set: { productId: 7009601257571, variantIds: [42967038230627,42967038263395] },//white,black
};

export const prodValidProductIds = [
  "gid://shopify/Product/4167455015011",
  "gid://shopify/Product/6633840476259",
  "gid://shopify/Product/6546274156643",
  "gid://shopify/Product/6878347133027",
  "gid://shopify/Product/4167630651491",
  "gid://shopify/Product/7009601257571",
];

export const prodBannerProductId = "7009601257571";
export const prodBannerVariantId = ["42967038263395","42967038230627"];//black,white
export const prodRecommendedSetProductId = "7009601257571";
export const prodRecommendedSetVariantId = ["42967038263395","42967038230627"];//black,white
