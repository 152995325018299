
interface Product {
    productId: number;
    variantIds: number[];
  }
  
 export const devSlugMappings: { [key: string]: Product } = {
    book: { productId: 9631149359380, variantIds: [49650942116116,49650942083348] },//ebook,paperback
    band: { productId: 9632397197588, variantIds: [49654021849364] },
    orbit: { productId: 9632397033748, variantIds: [49654020604180] },
    range: { productId: 9632397525268, variantIds: [49654022734100,49730583757076]},//white,black
    mark: { productId: 9631149949204, variantIds: [49730599584020,49730599616788] },//white,black
    set: { productId: 9631150244116, variantIds: [49730528248084,49730528280852] },//white,black
  };
  

  export const setHrefValue = (slug:string)=>{
    if (slug === "book") {
        return "/book";
      } else if (slug === "band") {
        return"/band";
      } else if (slug === "orbit") {
        return "/orbit";
      } else if (slug === "range") {
        return "/range";
      } else if (slug === "mark") {
        return "/mark";
      }
  }

  export const devValidProductIds = [
    "gid://shopify/Product/9631149949204",
    "gid://shopify/Product/9632397033748",
    "gid://shopify/Product/9632397197588",
    "gid://shopify/Product/9632397525268",
    "gid://shopify/Product/9631149359380",
    "gid://shopify/Product/9631150244116",
  ];
  
  export const devBannerProductId = '9631150244116';
  export const devBannerVariantId = ['49730528280852','49730528248084'];//black,white
  export const devRecommendedSetProductId = '9631150244116';
  export const devRecommendedSetVariantId = ['49730528280852','49730528248084'];//black,white