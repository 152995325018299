export const YOUTUBE_VIDEO_URL_TEMP =
  "https://www.youtube.com/embed/cVj_Jb5NMR4?si=89biwWnHD2Ylkmms";

export const NEXT_PUBLIC_API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
export const S3_HOST_NAME = process.env.NEXT_PUBLIC_S3_HOST_NAME;
export const S3_BASE_URL = process.env.NEXT_PUBLIC_S3_BASE_URL;
export const NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN =
  process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN;

export const HOME_PAGE_ID = 1;
export const SHOP_PAGE_ID = 2;
export const LEARN_PAGE_ID = 3;
export const REVALIDATE_TIME = 30;

export const FIRST_ROW = 1;
export const SECOND_ROW = 2;
export const THIRD_ROW = 3;
export const FOURTH_ROW = 4;
export const FIVFTH_ROW = 5;
export const SIXTH_ROW = 6;

export const PAGES = "/pages";
export const USA_REGION_ID = 2;
export const HOME_SLUG = "home";
export const ABOUT_US_SLUG = "aboutus";
export const USES = "uses";
export const SUBSCRIBERS = "/subscribers";
export const PRODUCT_LIST = "/products";

export const HOME_META_SLUG = "home";
export const ABOUT_META_SLUG = "about";
export const HOW_TO_USE_META_SLUG = "how-to-use";
export const LEARN_META_SLUG = "learn";
export const AFFFILATES_META_SLUG = "affilates";

export const MANUAL_META_SLUG = "manuals-safety";
export const PATENT_META_SLUG = "patent";
export const CONTACT_META_SLUG = "contact";

export const getTypes = (type: number) => {
  switch (type) {
    case 11:
      return "Ebook";
    case 12:
      return "Paperback";
    case 13:
      return "Red";
    case 14:
      return "Green";
    case 3:
      return "Yellow";
    case 4:
      return "Black";
    case 5:
      return "White";
    case 10:
      return "Orange"; // Added orange
    default:
      return "Default Type"; // Default message if no match
  }
};

