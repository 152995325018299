'use client'

import { createCart, fetchCart, fetchCheckoutData } from '@/utils/Shopify/products';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface CartContextType {
    cart: null,
    setCart: any,
    itemCount: number;
    setItemCount: (count: number) => void;

}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [itemCount, setItemCount] = useState(0);
    const [cart, setCart] = useState(null);

    useEffect(() => {
        const fetchCartData = async () => {
            const storedData = localStorage.getItem('cartCheckoutData');
            if (storedData) {
                const { cartId, checkoutid } = JSON.parse(storedData);
                if (cartId) {
                    const updatedCart = await fetchCart(cartId);
                    setCart(updatedCart);
                    setItemCount(updatedCart.lines.edges.reduce(
                        (total: any, edge: any) => total + edge.node.quantity,
                        0
                      ));

                    if (checkoutid) {
                        const checkout = await fetchCheckoutData(checkoutid);
                        if (checkout.completedAt) {
                            localStorage.removeItem('cartCheckoutData'); // Clear cart on checkout completion
                            setCart(null); // Clear the cart state
                            setItemCount(0); // Reset item count
                        }
                    }
                }
            } else {
                const newCart = await createCart();
                localStorage.setItem('cartCheckoutData', JSON.stringify({ cartId: newCart, checkoutId: null }));
                setCart(newCart);
                setItemCount(0); // Reset item count
            }
        };
        fetchCartData();
    }, []);

    useEffect(() => {
        const storedData = localStorage.getItem('cartCheckoutData');
        if (!storedData) {
            // No cartCheckoutData found, so we clear the cart state
            setCart(null);
            setItemCount(0);
        }
    }, []);

    return (
        <CartContext.Provider value={{ cart, setCart, itemCount, setItemCount }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (context === undefined) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};
