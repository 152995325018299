import { NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN } from "@/utils/constants";
import { validProductIds } from "../baseEnvConstant";


const shopifyAccessToken = process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN;

const headers: HeadersInit = {
    'Content-Type': 'application/json',
};


if (shopifyAccessToken) {
    headers['X-Shopify-Storefront-Access-Token'] = shopifyAccessToken;
}

export const fetchProducts = async () => {
    const query = `
    query {
      products(first: 50) {
        edges {
          node {
            id
            title
           
           
     variants(first: 10) {
            edges {
              node {
              id
              title
                priceV2 {
                  amount
                  currencyCode
                }
                compareAtPriceV2 {
                amount
                currencyCode
              }
              }
            }
          }
          }
        }
      }
    }
  `;
    const response = await fetch(`https://${NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ query }),
    });

    const data = await response.json();
    const allProducts = data.data.products.edges;

    // Filter products that match the valid product IDs
    const validProducts = allProducts.filter((productEdge: any) => {
        const product = productEdge.node;
        // Check if the product id is in the valid product ID list
        return validProductIds.includes(product.id);
    });
    return validProducts;
};



export const createCart = async () => {
    const mutation = `
      mutation {
        cartCreate {
          cart {
            id
          }
        }
      }
    `;

    const response = await fetch(`https://${NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ query: mutation }),
    });

    const data = await response.json();
    return data.data.cartCreate.cart.id;
};

export const addProductToCart = async (cartId: string, variantId: string, quantity: number) => {
    variantId = 'gid://shopify/ProductVariant/' + variantId;
    const mutation = `
      mutation($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            id
            lines(first: 5) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {                
                        id
                        product{
                        title
}
                      
                      priceV2 {
                        amount
                        currencyCode
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
        cartId,
        lines: [
            {
                merchandiseId: variantId,
                quantity,
            },
        ],
    };

    const response = await fetch(`https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ query: mutation, variables }),
    });

    const data = await response.json();
    return data.data.cartLinesAdd.cart;
};

export const fetchCart = async (cartId: string) => {
    const query = `
      query($cartId: ID!) {
        cart(id: $cartId) {
          id
          lines(first: 50) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {          
                      id
                      sku  
                      product{
                        id
                        title
                      images(first: 10) {
                        edges {
                          node {
                            src
                            altText
                          }
                        }
                      }
                    }                   
                    priceV2 {
                      amount
                      currencyCode
                    }
                    selectedOptions { 
                      name  
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = { cartId };

    if (shopifyAccessToken) {
        headers['X-Shopify-Storefront-Access-Token'] = shopifyAccessToken;
    }

    const response = await fetch(`https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ query, variables }),
    });

    const data = await response.json();
    return data.data.cart;
};

export const updateCartLineQuantity = async (cartId: string, lineId: string, quantity: number) => {
    const mutation = `
      mutation($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            id
            lines(first: 5) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                id
                product{
                        title
}
                      
                      priceV2 {
                        amount
                        currencyCode
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
        cartId,
        lines: [
            {
                id: lineId,
                quantity,
            },
        ],
    };

    const response = await fetch(`https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ query: mutation, variables }),
    });

    const data = await response.json();
    return data.data.cartLinesUpdate.cart;
};

export const clearCart = async (cartId: string) => {
    //  Fetch the cart lines
    const fetchCartLinesQuery = `
      query getCartLines($cartId: ID!) {
        cart(id: $cartId) {
          id
          lines(first: 50) {  # You can adjust the number of lines if needed
            edges {
              node {
                id  # Get the line item ID
              }
            }
          }
        }
      }
    `;

    // Fetch the cart lines from Shopify
    const fetchCartLinesResponse = await fetch(`https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            query: fetchCartLinesQuery,
            variables: { cartId },
        }),
    });

    const cartData = await fetchCartLinesResponse.json();

    //  Check if there are any cart lines and extract their IDs
    const lineIds = cartData.data.cart?.lines.edges.map((edge: any) => edge.node.id) || [];

    // If no cart lines are found, return immediately with the existing cart
    if (lineIds.length === 0) {
        return cartData.data.cart;  // Return the existing empty cart
    }

    //  Remove all cart lines using the cartLinesRemove mutation
    const mutation = `
      mutation($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            lines(first: 5) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    `;

    // Send the request to remove all lines
    const removeCartResponse = await fetch(`https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            query: mutation,
            variables: {
                cartId,
                lineIds,  // Pass the extracted lineIds to remove all cart items
            },
        }),
    });

    const removeData = await removeCartResponse.json();

    //  Return the updated cart (or an error message if failed)
    return removeData.data.cartLinesRemove.cart;
};


export const removeProductFromCart = async (cartId: string, lineId: string) => {
    const mutation = `
        mutation($cartId: ID!, $lineIds: [ID!]!) {
          cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
            cart {
              id
              lines(first: 10) {
                edges {
                  node {
                    id
                    quantity
                    merchandise {
                      ... on ProductVariant {
                        id
                        product{
                        title
                        }
                         priceV2 {
                          amount
                          currencyCode
                        }
                      }
                    }
                  }
                }
              }
            }
            userErrors {
              field
              message
            }
          }
        }
      `;

    const variables = {
        cartId,
        lineIds: [lineId],
    };

    const response = await fetch(`https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ query: mutation, variables }),
    });

    const data = await response.json();
    return data.data.cartLinesRemove.cart;
};

export const createCheckout = async (cartId: string) => {
    const mutation = `
        mutation($lineItems: [CheckoutLineItemInput!]!) {
              checkoutCreate(input: { lineItems: $lineItems }) {
                  checkout {
                      id
                      webUrl  # URL to redirect user to the checkout page
                      lineItems(first:10) {
                       edges {
                node {
                          
                          quantity
                         
                      }
  }
  }
                      subtotalPriceV2 {
                          amount
                          currencyCode
                      }
                  }
                  userErrors {
                      field
                      message
                  }
              }
          }
      `;
    const cart = await fetchCart(cartId);
    const cartLines = cart ? cart.lines.edges : [];

    if (!cartLines.length) {
        throw new Error('No items found in the cart.');
    }

    const lineItems = cartLines.map((edge: any) => ({
        variantId: edge.node.merchandise.id,
        quantity: edge.node.quantity,
    }));

    const variables = {
        lineItems,
        orderStatusUrl: "http://localhost:3000/shop",
    };

    const response = await fetch(`https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ query: mutation, variables }),
    });

    const data = await response.json();

    if (data.data.checkoutCreate.userErrors.length) {
        throw new Error(data.data.checkoutCreate.userErrors[0].message);
    }

    return data.data.checkoutCreate.checkout;
};


export const fetchCheckoutData = async (checkoutId: string) => {
    const query = ` query checkoutQuery($checkoutId: ID!) { node(id: $checkoutId) { ... on Checkout { id completedAt lineItems(first: 10) { edges { node { title quantity } } } } } } `;
    const response = await fetch(`https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-01/graphql.json`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            query: query,
            variables: { checkoutId },
        }),
    });
    const { data } = await response.json();
    return data?.node;
}
